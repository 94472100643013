$(function () {
    // "use strict";
    // closes the sidebar menu
    $("#menu-close").click(function (e) {
        e.preventDefault();
        $("#sidebar-wrapper").toggleClass("active");
    });
    // opens the sidebar menu
    $(".menu-toggle").click(function (e) {
        e.preventDefault();
        $("#sidebar-wrapper").toggleClass("active");
        $(".menu-toggle > .fa-bars, .menu-toggle > .fa-times").toggleClass("fa-bars fa-times");
        $(this).toggleClass("active");
    });
    // smooth scrolling using jQuery easing
    $("a.js-scroll-trigger[href*='#']:not([href='#'])").click(function () {
        if (location.pathname.replace(/^\//, "") === this.pathname.replace(/^\//, "") && location.hostname === this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
            if (target.length) {
                $("html, body").animate({
                    scrollTop: target.offset().top
                }, 1000, "easeInOutExpo");
                return false;
            }
        }
    });
    // closes responsive menu when a scroll trigger link is clicked
    $("#sidebar-wrapper .js-scroll-trigger").click(function () {
        $("#sidebar-wrapper").removeClass("active");
        $(".menu-toggle").removeClass("active");
        $(".menu-toggle > .fa-bars, .menu-toggle > .fa-times").toggleClass("fa-bars fa-times");
    });
    // scroll to top button appear
    $(document).scroll(function () {
        var scrollDistance = $(this).scrollTop();
        if (scrollDistance > 100) {
            $(".scroll-to-top").fadeIn();
        }
        else {
            $(".scroll-to-top").fadeOut();
        }
    });
    // end of use strict
    // disable Google Maps scrolling
    // see http://stackoverflow.com/a/25904582/1607849
    // disable scroll zooming and bind back the click event
    function onMapMouseleaveHandler() {
        var that = $(this);
        that.on("click", onMapClickHandler);
        that.off("mouseleave", onMapMouseleaveHandler);
        that.find("iframe").css("pointer-events", "none");
    }
    ;
    function onMapClickHandler() {
        var that = $(this);
        // disable the click handler until the user leaves the map area
        that.off("click", onMapClickHandler);
        // enable scrolling zoom
        that.find("iframe").css("pointer-events", "auto");
        // handle the mouse leave event
        that.on("mouseleave", onMapMouseleaveHandler);
    }
    // enable map zooming with mouse scroll when the user clicks the map
    $(".map").click(onMapClickHandler);
    $(document).scroll(function () {
        var $nav = $(".navbar-fixed-top");
        $nav.toggleClass("scrolled", $(this).scrollTop() > $nav.height());
    });
    // when the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            document.getElementById("to-top").style.display = "block";
        }
        else {
            document.getElementById("to-top").style.display = "none";
        }
    };
    // when the user clicks on the button, scroll to the top of the document
    $("#to-top").click(function () {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    });
    $("input").focus(function () {
        $(this).parents(".form-group").addClass("focused");
    });
    $("input").blur(function () {
        var inputValue = $(this).val();
        if (inputValue === "") {
            $(this).removeClass("filled");
            $(this).parents(".form-group").removeClass("focused");
        }
        else {
            $(this).addClass("filled");
        }
    });
    $("textarea").focus(function () {
        $(this).parents(".form-group").addClass("focused");
    });
    $("textarea").blur(function () {
        var textareaValue = $(this).val();
        if (textareaValue === "") {
            $(this).removeClass("filled");
            $(this).parents(".form-group").removeClass("focused");
        }
        else {
            $(this).addClass("filled");
        }
    });
    /*Scroll to top when arrow up clicked BEGIN*/
    $("#to-top").click(function (event) {
        event.preventDefault();
        $("html, body").animate({
            scrollTop: 0
        }, "slow");
        return false;
    });
    $('li a').removeClass("active");
    var pathname = document.location.pathname;
    if (pathname === '/') {
        $('#home').addClass("active");
        $('#home-r').addClass("active");
    }
    else if (pathname === '/company') {
        $('#company').addClass("active");
        $('#company-r').addClass("active");
    }
    else if (pathname === '/services') {
        $('#services').addClass("active");
        $('#services-r').addClass("active");
    }
    else if (pathname === '/connect') {
        $('#connect').addClass("active");
        $('#connect-r').addClass("active");
    }
    else if (pathname === '/contact') {
        $('#contact').addClass("active");
        $('#contact-r').addClass("active");
    }
    // modal forms with submittable content, will close after successful submit with small delay
    function setupModalForm(modalId, formId, confirmId) {
        $(confirmId).hide();
        $(formId).submit(function () {
            $(formId).hide();
            $(confirmId).show();
            setTimeout(function () {
                $(modalId).modal("hide");
            }, 2000);
        });
    }
    setupModalForm("#register-modal", "#register-form", "#register-form-confirm");
    setupModalForm("#subscribe-modal", "#subscribe-form", "#subscribe-form-confirm");
    setupModalForm("#messageModal", "#message-box-form", "#message-box-confirm");
    // when navigating to the page show modal specified in the has urlused in legal content
    // we want to provide perm-links which will show up when navigated to
    if (window.location.hash) {
        var hash = window.location.hash;
        if (["#terms", "#privacy"].includes(hash)) {
            window.location.assign(hash.substring(1));
        }
        else {
            $(hash + ".modal").modal("toggle");
        }
    }
});
var _global = window;
/*sidenav animated*/
_global.openNav = function () {
    document.getElementById("mySidebar").style.width = "100%";
};
_global.closeNav = function () {
    document.getElementById("mySidebar").style.width = "0";
};
